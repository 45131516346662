
import { Component, Vue, Mixins, Prop } from "vue-property-decorator";
// 混入
import Loading from "@/mixins/loading";
import TablePagination from "@/mixins/tablePagination";
// api
import { getResourceCategoryAll, deleteResourceCategory } from "@/api/resource";
import { ResourceCategory } from "@/types/api/resource";

@Component
export default class Table extends Mixins(Loading, TablePagination) {
  private tableData: Array<ResourceCategory> = [];

  mounted() {
    this.getData();
  }

  /**
   * methods
   */
  getData() {
    this.showLoading();
    getResourceCategoryAll().then((res: any) => {
      this.tableData = res.data;
      this.hideLoading();
    });
  }
  // 删除
  handleDelete(row: any) {
    this.$confirm(`是否要删除资源分类编号 ${row.id} ?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      deleteResourceCategory({
        id: row.id,
      }).then((res: any) => {
        this.getData();
        this.$message.success("删除成功");
      });
    });
  }
}
