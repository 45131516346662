
import { Component, Vue, Mixins, Prop } from "vue-property-decorator";
// 混入
import Loading from "@/mixins/loading";
// api
import {
  getResource,
  getResourceById,
  getResourceCategoryAll,
} from "@/api/resource";
import { Resource, ResourceCategory } from "@/types/api/resource";
// 组件
import Table from "./components/Table.vue";
import Header from "./components/Header.vue";
import Dialog from "./components/Dialog.vue";
// 工具
import copy from "@/utils/deepCopy";

@Component({
  components: {
    Table,
    Header,
    Dialog,
  },
})
export default class ResourceCategoryPage extends Mixins(Loading) {
  dialogVisible: boolean = false;
  urlKeyword: string = "";
  nameKeyword: string = "";
  categoryId: number = 0;
  type: string = "add";
  initForm: ResourceCategory = {
    createTime: "",
    id: 0,
    name: "",
    sort: 0,
  };
  form: ResourceCategory = this.initForm;

  // 输入内容
  inputValue(val: string, key: string) {
    let self: any = this;
    self[key] = val;
  }
  // 添加
  handleAdd() {
    this.type = "add";
    this.form = copy(this.initForm);
    this.dialogVisible = true;
  }
  // 编辑
  handleEdit(row) {
    this.showLoading();
    this.type = "edit";
    this.form = copy(row);
    this.hideLoading();
    this.dialogVisible = true;
  }
  // 获取数据
  getData() {
    let table: any = this.$refs.table;
    table.getData();
  }
}
